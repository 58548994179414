import React, { Component } from "react"
class Intro extends Component {
  render() {
    return (
      <div id="intro" className="container borders section-spacing">
        <div className="columns">
          <div className="column is-12">
            <div className="content first-content">
              <div className="sectionHeading">
                <h4 className="is-size-3 centerText">
                  Roofing Contractors in Scotland
                </h4>
                <hr />
              </div>
              <p>
                Stevenson Roofing is a family run business that has built up a
                strong reputation over many years in the business. We aim to
                surpass the expectations of our customers through our excellent
                service and workmanship. We take on all types of jobs, from
                small and domestic, to large commercial projects. All at very
                competitive prices.
              </p>
            </div>

            <div className="centerText">
              <a href="#about" className="button is-link is-medium">
                Discover More
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Intro
