import React from "react"
import Layout from "../components/Layout"

import Intro from "../components/Intro"
import About from "../components/About"
import Services from "../components/Services"
import RecentProjects from "../components/RecentProjects"
import Contact from "../components/Contact"

const seoProps = {
  title: "Roofing Contractor",
  description:
    "Stevenson Roofing is a family run roofing business based in Wishaw, Scotland with over 30 years of experience. Services include: Full roof installs, Lift and replace, Soffits and Facias, Firestone flat roofing, Guttering and down-piping, Single ply flat roofing, Lead work and more.",
}
const Home = () => {
  return (
    <Layout seoProps={seoProps}>
      <div className="divider" />
      <Intro />
      <div className="divider" />
      <About />
      <div className="divider" />
      <Services />
      <div className="divider" />
      <RecentProjects numOfImgs={6} />
      <div className="divider" />
      <Contact />
      <div className="divider" />
    </Layout>
  )
}

export default Home
