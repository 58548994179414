import React, { Component } from "react"
import { Link } from "gatsby"
import { routes } from "../routes/routes"
import { toolRoutes } from "../routes/imgRoutes"

class About extends Component {
  render() {
    return (
      <div id="about" className="section-spacing">
        <div className="container borders">
          <div className="columns">
            <div className="column is-12">
              <div className="sectionHeading">
                <h4 className="is-size-3 centerText">About us</h4>
                <hr />
              </div>
              <div className="content second-content">
                <div className="columns">
                  <div className="column is-7">
                    <img
                      src={`../${toolRoutes.sawing1.url}`}
                      alt={toolRoutes.sawing1.alt}
                    />
                  </div>
                  <div className="column is-5">
                    <div className="right-about-text">
                      <h4>Who we are?</h4>
                      <p>
                        Stevenson Roofing is a family run roofing business based
                        in Wishaw, Lanarkshire. We have been in the building
                        industry for over 30 years. We have a wealth of
                        experience running projects big and small. We pride
                        ourselves on providing extremely high quality work and
                        building strong lasting relationships with our clients.
                        Our client base continues to grow based on our
                        reputation as a well-regarded roofing business within
                        the Lanarkshire area.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-7">
                    <div className="left-about-text">
                      <h4>What we do?</h4>
                      <p>
                        We provide a vast range of roofing and building
                        services, from full roof installs to refit lift and
                        lays. Check our{" "}
                        <Link to={routes.services}>services</Link> page for more
                        information.
                        <br />
                        <br /> No job is too big or small and we would welcome
                        the opportunity to earn your trust by delivering the
                        best service in the industry. <br />
                        <br /> For more information about some of the services
                        we provide click the button below to find out more.
                      </p>
                      <div className="centerText">
                        <Link
                          className="button is-link is-medium"
                          to={routes.services}
                        >
                          Services
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="column is-5">
                    <img
                      className="takes-full-width"
                      src={`../${toolRoutes.tools1.url}`}
                      alt={toolRoutes.tools1.alt}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default About
