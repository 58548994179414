import React, { Component } from "react"
import { Link } from "gatsby"
import { routes } from "../routes/routes"
import { projectRoutes as img, featureImg } from "../routes/imgRoutes"

class RecentProjects extends Component {
  constructor(props) {
    super(props)

    if (props.numOfImgs) {
      this.state = {
        numOfImgs: props.numOfImgs,
        featureImg: false,
      }
    } else {
      this.state = {
        numOfImgs: Object.values(img).length,
        featureImg: true,
      }
    }
  }

  buildImages() {
    const imgArr = Object.values(img)
    const numOfRows = Math.ceil(this.state.numOfImgs / 3)
    let masterIndex = 0
    let columns = []

    for (var i = 0; i < numOfRows; i++) {
      columns.push(
        <div key={i} className="columns">
          {buildRow(imgArr)}
        </div>
      )
    }

    function buildRow(imgArr) {
      let images = []
      for (var j = 0; j < 3; j++) {
        images.push(
          <div key={"photo" + masterIndex} className="column is-4">
            <img
              className="takes-full-width"
              src={`../${imgArr[masterIndex].url}`}
              alt={imgArr[masterIndex].alt}
            />
          </div>
        )
        masterIndex++
      }

      return images
    }

    return columns
  }

  render() {
    let feature = null
    if (this.state.featureImg) {
      feature = (
        <div className="columns">
          <div className="column is-12">
            <img
              className="takes-full-width"
              src={`../${featureImg.longroof1.url}`}
              alt={featureImg.longroof1.alt}
            />
          </div>
        </div>
      )
    }
    const images = this.buildImages()
    return (
      <div id="recent-projects" className="container borders section-spacing">
        <div className="columns">
          <div className="column is-12">
            <div className="sectionHeading">
              <h4 className="is-size-3 centerText">Recent Projects</h4>
              <hr />
            </div>
            {feature}
            {images}
            <div className="centerText">
              <Link to={routes.contact} className="button is-medium is-link">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RecentProjects
